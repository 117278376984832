import "./App.css";

function App() {
  return (
    <div className="main">
      <div className="logodiv">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img
            className="img-fluid "
            src={require("./assets/img/logo.png").default}
            alt=""
          />
        </div>
      </div>

      <div className="anidiv">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img
            style={{ height: "350px", borderRadius: "3rem" }}
            className="img-fluid dogimg"
            src={require("./assets/img/1.png").default}
            alt=""
          />
        </div>
      </div>

      <div className="footerdiv">
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="text-center">
            <div className="">
              <p className="subtitle mih1">
                PREMIUM PET PRODUCTS! AMERICAN STYLE!
              </p>
            </div>

            <div className="mt-2">
              <span className="text-white mih1">COMING SOON!</span>
              <div className="">EMAIL......</div>
              <p className="subtitle">info@patriotpetshop.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
